var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2a51cf1f3af3e543f7286ce6ce9b6ad0846d1c1a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
	init({
		dsn:
			SENTRY_DSN ||
			"https://fc60b1d58e89481ebbe69659cf79a0e5@o1229088.ingest.sentry.io/6504987",
		environment: process.env.NEXT_PUBLIC_JULY_ENV,
	});
}
